.modal_container {
    position: fixed;
    background-color: rgba(30,30,30,60%);
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    background-color: #fff;
    width: 80%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-radius: 10px;
    height: 350px;
    position: fixed;
    top: 15%;
}

.button_close {
    position: absolute;
    top: 2%;
    left: 87%;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.modal h3 {
    font-size: 26px;
    font-weight: bold;
    margin: 10px 0;
}

.form_container {
    display: flex;
    flex-direction: column;
}

.form_container label {
    text-align: start;
    margin-bottom: 8px;
}

.form_container input {
    border: 1px solid #AAA5A5;
    border-radius: 10px;
    padding: 12px;
    font-size: 16px;
}
.form_container input:focus {
    outline: none;
}

.text_default {
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 0;
}

.description {
    text-align: center;
}

.button_modal {
    background-color: #252525;
    padding: 12px;
    border: none;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}

.button_modal:disabled {
    pointer-events: none;
}

.button_modal.loading {
    font-size: 0;
}

.loading-icon {
    font-size: 12px;
}

.loading-icon .spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media (min-width: 1024px) {
    .modal {
        width: 50%;
        top: 15%;
        left: 24%;
    }

    .button_close {
        left: 93%;
    }

    .modal_container {
        height: 100vw;
    }
}