.container_mosaic:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.container_mosaic:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}